<template>
  <div class="content overview-cont">
    <el-row :gutter="20" class="el-row-20 zl-list">
      <el-col :span="6">
        <el-card>
          <div class="flex">
            <div class="item">
              <p>
                24小时新增数据
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="24小时内，监测到的总信息数量"
                  placement="bottom"
                >
                  <i class="iconfont icon-tishi"></i>
                </el-tooltip>
              </p>
              <p class="cor-555 strong">{{ previewData.data_count.hour24 }}</p>
            </div>
            <img
              src="@/assets/images/overview/ov_icon1.svg"
              class="image"
              width="25"
              height="25"
            />
          </div>
          <div class="bottom">
            <p class="fz-12 mar-t-10">
              <span>周同比 {{ Math.abs(previewData.data_count.week) }}%</span>
              <i
                class="el-icon-caret-top red"
                v-if="previewData.data_count.week >= 1"
              ></i>
              <i class="el-icon-caret-bottom cor-blue" v-else></i>
              <span>日同比 {{ Math.abs(previewData.data_count.day) }}%</span>
              <i
                class="el-icon-caret-top red"
                v-if="previewData.data_count.day >= 1"
              ></i>
              <i class="el-icon-caret-bottom cor-blue" v-else></i>
            </p>
          </div>
        </el-card>
      </el-col>
      <el-col :span="6">
        <el-card>
          <div class="flex">
            <div class="item">
              <p>
                24小时新增敏感
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="24小时内，监测到的敏感信息数量"
                  placement="bottom"
                >
                  <i class="iconfont icon-tishi"></i>
                </el-tooltip>
              </p>
              <p class="cor-fc5 strong">
                {{ previewData.negative_count.hour24 }}
              </p>
            </div>
            <img
              src="@/assets/images/overview/ov_icon2.svg"
              class="image"
              width="25"
              height="25"
            />
          </div>
          <div class="bottom">
            <p class="fz-12 mar-t-10">
              <span
                >周同比 {{ Math.abs(previewData.negative_count.week) }}%</span
              >
              <i
                class="el-icon-caret-top red"
                v-if="previewData.negative_count.week >= 1"
              ></i>
              <i class="el-icon-caret-bottom cor-blue" v-else></i>
              <span
                >日同比 {{ Math.abs(previewData.negative_count.day) }}%</span
              >
              <i
                class="el-icon-caret-top red"
                v-if="previewData.negative_count.day >= 1"
              ></i>
              <i class="el-icon-caret-bottom cor-blue" v-else></i>
            </p>
          </div>
        </el-card>
      </el-col>
      <el-col :span="6">
        <el-card>
          <div class="flex">
            <div class="item">
              <p>
                24小时新增外媒数据
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="24小时内，检测到的外媒信息数量"
                  placement="bottom"
                >
                  <i class="iconfont icon-tishi"></i>
                </el-tooltip>
              </p>
              <p class="cor-8f6 strong">
                {{ previewData.foregin_count.hour24 }}
              </p>
            </div>
            <img
              src="@/assets/images/overview/ov_icon3.svg"
              class="image"
              width="25"
              height="25"
            />
          </div>
          <div class="bottom">
            <p class="fz-12 mar-t-10">
              <span
                >周同比 {{ Math.abs(previewData.foregin_count.week) }}%</span
              >
              <i
                class="el-icon-caret-top red"
                v-if="previewData.foregin_count.week >= 1"
              ></i>
              <i class="el-icon-caret-bottom cor-blue" v-else></i>
              <span>日同比 {{ Math.abs(previewData.foregin_count.day) }}%</span>
              <i
                class="el-icon-caret-top red"
                v-if="previewData.foregin_count.day >= 1"
              ></i>
              <i class="el-icon-caret-bottom cor-blue" v-else></i>
            </p>
          </div>
        </el-card>
      </el-col>
      <el-col :span="6">
        <el-card>
          <div class="flex">
            <div class="item">
              <p>
                24小时新增敏感占比
                <el-tooltip class="item" effect="dark" placement="bottom">
                  <div slot="content">
                    24小时内，监测到的敏感信息占总<br />信息数量的比重
                  </div>
                  <i class="iconfont icon-tishi"></i>
                </el-tooltip>
              </p>
              <p class="cor-fd9 strong">{{ previewData.hour24_percent }}%</p>
            </div>
            <img
              src="@/assets/images/overview/ov_icon4.svg"
              class="image"
              width="25"
              height="25"
            />
          </div>
          <el-progress
            :percentage="previewData.hour24_percent"
            :show-text="false"
            class="mar-t-15"
          ></el-progress>
        </el-card>
      </el-col>
    </el-row>
    <el-card class="mar-t-20 hot_focus" id="jdxx">
      <div class="mod-title ov-v">
        <span class="mar-r-5"
          >方案展示
          <el-tooltip class="item" effect="dark" placement="bottom">
            <div slot="content">
              24小时内，监测到的网媒、社交平台和<br />外媒的信息按热度计算，分别展示前5条<br />热门信息
            </div>
            <i class="iconfont icon-tishi"></i>
          </el-tooltip>
        </span>
        <feature
          :filter="[]"
          v-on:downPhoto="downPhoto('jdxx')"
          :showDown="true"
        />
      </div>
      <el-row :gutter="20">
        <el-col :span="12" v-loading="loading.trend" class="mod-echarts-box">
          <div class="echarts_empty hide">
            <div class="img"></div>
          </div>
          <div id="trend" class="mod-echarts-cont mar-t-20"></div>
        </el-col>
        <el-col :span="12" v-loading="loading.hotpoint">
          <el-tabs v-model="activeName">
            <el-tab-pane label="网媒" name="first">
              <div class="mod-list">
                <ul>
                  <li>
                    <h3>
                      <span class="num-tit">序号</span>
                      <span class="mod-list-title2">信息内容</span>
                      <span class="mod-list-title1 t-c">信息来源</span>
                      <span class="tag">类型</span>
                    </h3>
                  </li>
                  <li
                    v-for="(data, index) in hotData.wangmei"
                    :key="index"
                    v-if="index <= 4"
                  >
                    <span class="num bg-555" v-if="index == 0">1</span>
                    <span class="num bg-689" v-else-if="index == 1">2</span>
                    <span class="num bg-8ea" v-else-if="index == 2">3</span>
                    <span class="num" v-else-if="index >= 3">{{
                      index + 1
                    }}</span>
                    <a
                      class="mod-list-title2"
                      @click="hotPointDetail(data.rowkey)"
                    >
                      <span :title="data.title">{{ data.title }}</span>
                      <i
                        class="iconfont icon-minganbiaoqian"
                        v-show="data.emotion === 'negative'"
                      ></i>
                    </a>
                    <a
                      href
                      class="mod-list-title1 t-c"
                      :title="data.source_name"
                      >{{ data.source_name }}</a
                    >
                    <span class="tag">{{ data.category }}</span>
                  </li>
                </ul>
              </div>
            </el-tab-pane>
            <el-tab-pane label="社交平台" name="second">
              <div class="mod-list">
                <ul>
                  <li>
                    <h3>
                      <span class="num-tit">序号</span>
                      <span class="mod-list-title2">信息内容</span>
                      <span class="mod-list-title1 t-c">信息来源</span>
                      <span class="tag">类型</span>
                    </h3>
                  </li>
                  <li
                    v-for="(data, index) in hotData.shejiao"
                    :key="index"
                    v-if="index <= 4"
                  >
                    <span class="num bg-555" v-if="index == 0">1</span>
                    <span class="num bg-689" v-else-if="index == 1">2</span>
                    <span class="num bg-8ea" v-else-if="index == 2">3</span>
                    <span class="num" v-else-if="index >= 3">{{
                      index + 1
                    }}</span>
                    <a
                      class="mod-list-title2"
                      @click="hotPointDetail(data.rowkey)"
                    >
                      <span :title="data.title">{{ data.title }}</span>
                      <i
                        class="iconfont icon-minganbiaoqian"
                        v-show="data.emotion === 'negative'"
                      ></i>
                    </a>
                    <a
                      href
                      class="mod-list-title1 t-c"
                      :title="data.source_name"
                      >{{ data.source_name }}</a
                    >
                    <span class="tag">{{ data.category }}</span>
                  </li>
                </ul>
              </div>
            </el-tab-pane>
            <el-tab-pane label="外媒" name="third">
              <div class="mod-list">
                <ul>
                  <li>
                    <h3>
                      <span class="num-tit">序号</span>
                      <span class="mod-list-title2">信息内容</span>
                      <span class="mod-list-title1 t-c">信息来源</span>
                      <span class="tag">类型</span>
                    </h3>
                  </li>
                  <li
                    v-for="(data, index) in hotData.waimei"
                    :key="index"
                    v-if="index <= 4"
                  >
                    <span class="num bg-555" v-if="index == 0">1</span>
                    <span class="num bg-689" v-else-if="index == 1">2</span>
                    <span class="num bg-8ea" v-else-if="index == 2">3</span>
                    <span class="num" v-else-if="index >= 3">{{
                      index + 1
                    }}</span>
                    <a
                      class="mod-list-title2"
                      @click="hotPointDetail(data.rowkey)"
                    >
                      <span :title="data.title">{{ data.title }}</span>
                      <i
                        class="iconfont icon-minganbiaoqian"
                        v-show="data.emotion === 'negative'"
                      ></i>
                    </a>
                    <a
                      href
                      class="mod-list-title1 t-c"
                      :title="data.source_name"
                      >{{ data.source_name }}</a
                    >
                    <span class="tag">{{ data.category }}</span>
                  </li>
                </ul>
              </div>
            </el-tab-pane>
          </el-tabs>
        </el-col>
      </el-row>
    </el-card>
    <el-row :gutter="20" class="el-row-20 mar-t-20">
      <el-col :span="12">
        <el-card
          class="box-card mod-echarts-box"
          id="mediaMapCard"
          v-loading="loading.originPie"
        >
          <div class="mod-title">
            <span class="mar-r-5">媒体类型分布</span>
            <el-tooltip class="item" effect="dark" placement="bottom">
              <div slot="content">
                时间段内，各媒体发布的信息占总<br />信息数量的比重
              </div>
              <i class="iconfont icon-tishi"></i>
            </el-tooltip>
            <feature
              :filter="[]"
              v-on:downPhoto="downPhoto('mediaMapCard')"
              :showDown="true"
            />
          </div>
          <div class="echarts_empty hide">
            <div class="img"></div>
          </div>
          <div class="mod-echarts-cont" id="originPie"></div>
        </el-card>
      </el-col>
      <el-col :span="12">
        <el-card
          class="mod-echarts-box hot-wordcloud"
          v-loading="loading.hotkey"
          id="hotkeyCard"
        >
          <div class="mod-title ov-v">
            <span class="mar-r-5">热门词云</span>
            <el-tooltip class="item" effect="dark" placement="bottom">
              <div slot="content">
                对24小时内监测到的信息内容进行<br />分词和重要关键词词频统计，展示<br />词频最高的50个重要关键词
              </div>
              <i class="iconfont icon-tishi"></i>
            </el-tooltip>
            <feature
              :filter="[]"
              v-on:downPhoto="downPhoto('hotkeyCard')"
              :showDown="true"
            />
            <i
              :class="{
                iconfont: true,
                'icon-ciyun': true,
                selected: hotkeyShowState === 'chart',
              }"
              @click="hotkeyShowState = 'chart'"
            ></i>
            <i
              :class="{
                iconfont: true,
                'icon-cipin': true,
                selected: hotkeyShowState === 'table',
              }"
              @click="hotkeyShowState = 'table'"
            ></i>
          </div>
          <div class="echarts_empty" v-show="hotKeyData.length < 1">
            <div class="img"></div>
          </div>
          <div
            class="mod-echarts-cont"
            id="hotkey"
            v-show="hotkeyShowState === 'chart' && hotKeyData.length >= 1"
          ></div>
          <div
            class="table-list"
            v-show="hotkeyShowState === 'table' && hotKeyData.length >= 1"
          >
            <ul>
              <li>
                <h3>排名</h3>
                <h3>热门关键词</h3>
                <h3>信息数量</h3>
              </li>
              <li v-for="(item, index) in hotKeyData" :key="index">
                <span>{{ index + 1 }}</span>
                <span>{{ item.name }}</span>
                <span>{{ item.value }}</span>
              </li>
            </ul>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <el-row :gutter="20" class="mar-t-20 el-row-20">
      <el-col :span="12">
        <el-card
          class="industry-information"
          id="industryData"
          v-loading="loading.industry"
        >
          <div class="mod-title ov-v">
            <span class="mar-r-5">行业重点信息</span>
            <el-tooltip class="item" effect="dark" placement="bottom">
              <div slot="content">24小时内各行业重点信息</div>
              <i class="iconfont icon-tishi"></i>
            </el-tooltip>
            <feature
              :filter="[]"
              v-on:downPhoto="downPhoto('industryData')"
              :showDown="true"
            />
          </div>
          <el-carousel indicator-position="outside" arrow="never" class="mar-t-5">
            <el-carousel-item v-for="(item, index) in industryData" :key="index">
              <ul class="mod-list lh-45">
                <li v-for="(row, i) in item" :key="i">
                  <i class="tag" :title="row.label">{{ row.label }}</i>
                  <a @click="detail(row.rowkey)" class="mod-list-title2" :title="row.title">{{ row.title }}</a>
                </li>
              </ul>
            </el-carousel-item>
          </el-carousel>
        </el-card>
      </el-col>
      <el-col :span="12">
        <el-card
          v-loading="loading.hotarea"
          class="mod-echarts-box hot-area"
          id="hotareaCard"
        >
          <div class="mod-title ov-v">
            <span class="mar-r-5">地区声量分布</span>
            <el-tooltip class="item" effect="dark" placement="bottom">
              <div slot="content">
                24小时内，监测到的各地区发布信<br />息数量
              </div>
              <i class="iconfont icon-tishi"></i>
            </el-tooltip>
            <feature
              :filter="[]"
              v-on:downPhoto="downPhoto('hotareaCard')"
              :showDown="true"
            />
          </div>
          <div class="echarts_empty hide">
            <div class="img"></div>
          </div>
          <div>
            <div class="mod-echarts-cont" id="hotarea"></div>
            <div
              class="hotarea-content"
              v-show="hotareaData.length >= 1"
              v-loading="loading.hotarea"
            >
              <h3>地区声量排行</h3>
              <ul class="mod-list lh-36">
                <li
                  v-for="(item, index) in hotareaData"
                  :key="index"
                  v-if="index <= 2"
                >
                  <span class="num bg-555" v-if="index == 0">1</span>
                  <span class="num bg-689" v-else-if="index == 1">2</span>
                  <span class="num bg-8ea" v-else-if="index == 2">3</span>
                  <a class="mod-list-title1" :title="item.value + '条'"
                    >{{ item.name }} {{ item.value }}条</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <p class="jszc_text" v-if="version === 'shwj'">
      技术支持：上海索思数据科技有限公司
    </p>
    <article-detail ref="detail" />
    <article-list ref="list" />
  </div>
</template>
<script type="application/javascript">
import articleDetail from "@components/common/article-detail.vue";
import articleList from "@components/common/article-list.vue";
import {
  dom2image,
  globalGetEcharts,
  globalCreateChart,
  globalFormatDate,
} from "@/utils/helpers.js";
import feature from "@components/common/feature.vue";
import {
  preview as previewReq,
  hotpoint as hotpointReq,
  trend as trendReq,
  hotcloud,
  areapoint,
  originPieChart,
  industryData,
} from "@/api/overview";
const _ = window._;
export default {
  name: "component-overview",
  data() {
    return {
      version: process.env.VUE_APP_VERSION,
      activeName: "first",
      previewData: {
        category_percent: { net_media: 0, other: 0, wechat: 0, weibo: 0 },
        data_count: { day: 0, hour24: 0, week: 0 },
        hour24_percent: 0,
        negative_count: { day: 0, hour24: 0, week: 0 },
        foregin_count: { hour24: 0, week: 0, day: 0 },
      },
      loading: {
        trend: true,
        hotpoint: true,
        originPie: true,
        industry: true,
        hotkey: true,
        hotarea: true,
      },
      hotData: [],
      riseWords: [],
      hotareaData: [],
      hotKeyData: [],
      industryData: [],
      emptyIndustry: false,
      hotkeyShowState: "chart",
    };
  },
  watch: {},
  mounted() {
    this.$nextTick(() => {
      this.loadData();
    });
  },
  components: {
    articleDetail,
    articleList,
    feature,
  },
  methods: {
    downPhoto(id) {
      dom2image(window.document.getElementById(id), id);
    },
    loadData() {
      const _this = this;
      let modules = [];
      _([
        "preview",
        "trend",
        "hotpoint",
        "originPie",
        "wordcloud",
        "industry",
        "hotarea",
      ]).forEach((item, i) => {
        var func = function () {
          return new Promise(function (resolve, reject) {
            _this[item](resolve);
          });
        };
        modules.push(func);
      });
      async function queue(arr) {
        let res = null;
        for (let promise of arr) {
          res = await promise(res);
        }
        return await res;
      }
      // promise 队列
      queue(modules).then((data) => {
        // done
      });
    },
    preview(resolve = null) {
      previewReq()
        .then((res) => {
          if (res.data.state) {
            this.previewData = res.data.data;
          }
          if (resolve) resolve("preview done");
        })
        .catch((res) => {
          if (resolve) resolve("preview done");
          this.$message.error("获取总览实时数据失败，服务错误");
        });
    },
    hotpoint(resolve = null) {
      this.loading.hotpoint = true;
      hotpointReq()
        .then((res) => {
          if (res.data.state) {
            this.hotData = res.data.data;
          }
          this.loading.hotpoint = false;
          if (resolve) resolve("hotpoint done");
        })
        .catch((err) => {
          if (resolve) resolve("hotpoint done");
          this.$message.error("获取热门焦点，服务错误");
          window.console.log(err);
        });
    },
    trend(resolve = null) {
      let echarts = globalGetEcharts();
      let option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        legend: {
          top: "5px",
          icon: "circle",
          itemWidth: 10,
          itemHeight: 10,
          itemGap: 20,
          show: false,
        },
        grid: {
          left: "20px",
          right: "20px",
          top: "20px",
          bottom: "0",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: [],
        },
        yAxis: {
          //            type : 'value',
          axisLabel: {
            formatter: function (value) {
              let v = (value / 10000).toFixed(2);
              return v <= 0 ? 0 : v + "万";
            },
          },
        },
        dataZoom: [
          {
            type: "inside",
            start: 0,
            end: 100,
          },
        ],
        color: "#65A4FB",
        series: [
          {
            name: "数据总量",
            type: "line",
            smooth: true,
            showSymbol: false,
            areaStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "#65A4FB",
                  },
                  {
                    offset: 1,
                    color: "#E4F0FF",
                  },
                ]),
              },
            },
            markPoint: {
              // symbol: 'image://http://yq.suosi.net.cn/images/repository/ico-1.svg',
              // symbolSize: '13',
              // data: [{type: 'max', name: '最大值', label: {show: false}}]
            },
            data: [],
          },
        ],
      };
      var _this = this;
      this.loading.trend = true;
      trendReq()
        .then((res) => {
          if (res.data.state) {
            let result = res.data.data;
            option.xAxis.data = result.keys.map(function (item, index) {
              let temp = item.split("-");
              temp.shift();
              return temp.join("-");
            });
            option.series[0].data = result.data;
            this.loading.trend = false;
            let element = window.document.getElementById("trend");
            if (!element) return false; // 切换时直接break
            var chart = globalCreateChart(element, option);
            this.$nextTick(() => {
              window.addEventListener("resize", () => {
                chart.resize();
              });
            });
            chart.off("click");
            chart.on("click", function (e) {
              _this.list("trend", { time: e.name, phonyTotal: e.value });
            });
          }
          if (resolve) resolve("trend done");
        })
        .catch((res) => {
          if (resolve) resolve("trend done");
          this.$message.error("获取数据走势，服务错误");
        });
    },
    originPie(resolve = null) {
      originPieChart()
        .then((res) => {
          if (resolve) resolve("originPie done");
          if (res.data.state) {
            this.loading.originPie = false;
            const { data } = res.data;
            let keys = [];
            let total = 0;
            const seriesData = data.map((item) => {
              keys.push(item.label);
              total += item.count;
              return {
                name: item.label,
                value: item.count,
                itemStyle: { color: this.getColor(item.label) },
              };
            });

            let option = {
              tooltip: {
                trigger: "item",
                formatter: "{a} <br/>{b} : {c} ({d}%)",
              },
              legend: {
                type: "scroll",
                itemWidth: 10,
                itemHeight: 10,
                orient: "horizontal",
                top: "10px",
                itemGap: 10,
                icon: "circle",
                data: keys,
                pageIconColor: "#555DFE",
              },
              series: [
                {
                  name: "来源分布",
                  type: "pie",
                  radius: ["40%", "60%"],
                  center: ["50%", "52%"],
                  data: seriesData,
                  avoidLabelOverlap: true,
                  label: {
                    formatter: function (params) {
                      const rate = ((params.value / total) * 100).toFixed(2);
                      return `${params.name} ${rate}%`;
                    },
                  },
                  emphasis: {
                    label: {
                      show: true,
                    },
                  },
                  itemStyle: {
                    emphasis: {
                      shadowBlur: 10,
                      shadowOffsetX: 0,
                      shadowColor: "rgba(0, 0, 0, 0.5)",
                    },
                  },
                },
              ],
            };
            this.$nextTick(() => {
              const element = window.document.getElementById("originPie");
              var myChart = globalCreateChart(element, option);
              myChart.resize();
              window.addEventListener("resize", () => {
                myChart.resize();
              });
            });
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch((e) => {
          if (resolve) resolve("originPie done");
          window.console.error(e);
        });
    },
    industry(resolve = null) {
      industryData()
        .then((res) => {
          if (resolve) resolve("industry done");
          if (res.data.state) {
            this.loading.industry = false;
            const { data } = res.data;
            this.industryData = window._.chunk(data, 5);
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch((e) => {
          if (resolve) resolve("industry done");
          window.console.error(e);
        });
    },
    // 热门词云
    wordcloud(resolve = null) {
      const _this = this;
      const colors = [ "#555DFE", "#4E89FE", "#8CB6FF", "#A5A5A5" ];
      this.loading.hotkey = true;
      hotcloud()
        .then((res) => {
          if (res.data.state) {
            var maskImage = new Image();
            //重点：云彩图片的base64码
            maskImage.src = require("@/assets/images/common/ciyun.jpg");
            var data = [];
            this.riseWords = res.data.data.rise;
            _(res.data.data.words).forEach((item, i) => {
              let index = Math.floor(Math.random() * colors.length)
              data.push({ name: item["word"], value: item["num"], textStyle: {
                  color: colors[index]
                } });
            });
            data = data.sort(function (a, b) {
              return b.value - a.value;
            });
            this.hotKeyData = data;
            let top10Data = { a: [], b: [], c: [], d: [] };
            _(data).forEach((item, index) => {
              if (index <= 2) {
                top10Data.a.push(item.name);
              } else if (index > 2 && index <= 5) {
                top10Data.b.push(item.name);
              } else if (index > 5 && index <= 10) {
                top10Data.c.push(item.name);
              } else if (index > 10) {
                top10Data.d.push(item.name);
              }
            });
            maskImage.onload = function () {
              let option = {
                tooltip: {
                  trigger: "item",
                },
                series: [
                  {
                    name: "信息数",
                    type: "wordCloud",
                    shape: "circle", // square circle cardioid diamond triangle-forward triangle
                    right: "0",
                    top: "0%",
                    width: "100%",
                    height: "100%",
                    sizeRange: [12, 48],
                    rotationRange: [0, 0],
                    rotationStep: 90,
                    hape: "pentagon",
                    gridSize: 16,
                    drawOutOfBound: false,
                    textStyle: {
                      normal: {
                        fontFamily: "sans-serif"
                      },
                      emphasis: {
                        shadowBlur: 10,
                        shadowColor: "#d2d2d2",
                      },
                    },
                    data: data,
                  },
                ],
              };

              let element = window.document.getElementById("hotkey");
              if (!element) return false; // 切换时直接break

              // element.style.width = '75%'
              // element.style.margin = '0 auto'

              _this.$nextTick(() => {
                option.series[0].maskImage = maskImage;
                var myChart = globalCreateChart(element, option);
                window.addEventListener("resize", () => {
                  myChart.resize();
                });
                _this.loading.hotkey = false;
                myChart.off("click");
                myChart.on("click", function (e) {
                  _this.list("hotkey", { keyword: e.data.name });
                });
              });
            };
          } else {
            _this.$message.error("加载关键词云错误");
          }
          if (resolve) resolve("wordcloud done");
        })
        .catch((err) => {
          if (resolve) resolve("wordcloud done");
          this.$message.error("加载关键词云错误");
        });
    },
    hotarea(resolve = null) {
      var _this = this;
      this.loading.hotarea = true;
      areapoint()
        .then((res) => {
          if (res.data.state) {
            _this.loading.hotarea = false;
            if (resolve) resolve("hotarea done");
            var result = res.data.data;
            var data = [];
            _.remove(result, (row) => {
              return row.name == "其他";
            });
            if (_.size(result) < 1) return false;
            result = result.sort((a, b) => {
              return b.value - a.value;
            });
            let max = result[0].value;
            this.hotareaData = result;
            _(result).forEach((item, i) => {
              if (window.provinceGeoCoord.hasOwnProperty(item.name)) {
                data.push({
                  name: item.name,
                  value: window.provinceGeoCoord[item.name].concat(item.value),
                });
              }
            });
            let option = {
              tooltip: {
                trigger: "item",
                formatter: function (params) {
                  return params.data.name + "： " + params.data.value[2] + "条";
                },
              },
              geo: {
                map: "china",
                label: {
                  emphasis: {
                    show: false,
                  },
                },
                roam: false,
                itemStyle: {
                  normal: {
                    areaColor: "#c1ceff",
                    borderColor: "#fff",
                  },
                  emphasis: {
                    areaColor: "#c1ceff",
                  },
                },
              },
              series: [
                {
                  name: "地域信息数",
                  data: data,
                  type: "effectScatter",
                  coordinateSystem: "geo",
                  symbolSize: function (val) {
                    return (val[2] / max) * 10 < 3 ? 3 : (val[2] / max) * 15;
                  },
                  showEffectOn: "render",
                  rippleEffect: {
                    brushType: "stroke",
                  },
                  hoverAnimation: true,
                  itemStyle: {
                    normal: {
                      color: "#0081e7",
                      shadowBlur: 10,
                      shadowColor: "#333",
                    },
                  },
                  zlevel: 1,
                },
              ],
            };
            let element = window.document.getElementById("hotarea");
            if (!element) return false; // 切换时直接break
            var myChart = globalCreateChart(element, option);
            _this.$nextTick(() => {
              window.addEventListener("resize", () => {
                myChart.resize();
              });
            });
            myChart.off("click");
            myChart.on("click", function (e) {
              _(result).forEach((item) => {
                if (e.name == item.name && item.value >= 1) {
                  _this.list("hotarea", { province: e.name });
                  return false;
                }
              });
            });
          } else {
            _this.$message.error(res.data.error);
          }
        })
        .catch((res) => {
          if (resolve) resolve("hotarea done");
          this.$message.error("加载地域分布错误");
        });
    },
    getColor(media) {
      switch (media) {
        case "网媒":
          return "#4E89FE";
        case "外媒":
          return "#8F65DD";
        case "其他":
          return "#8FADFE";
        case "社交平台":
          return "#27C2BA";
      }
    },
    detail(rowkey) {
      this.$refs.detail.showDetail(rowkey, "overview");
    },
    hotPointDetail(rowkey) {
      this.$refs.detail.showDetail(rowkey, "overview", null, null, 1);
    },
    list(key, args) {
      let date = new Date();
      let begin = new Date();
      begin.setTime(begin.getTime() - 3600 * 1000 * 12);
      let params = {
        span: globalFormatDate(begin) + "~" + globalFormatDate(date),
      };
      switch (key) {
        case "trend":
          let nowTime =
            date.getMonth() + 1 < parseInt(args.time.split("-")[0])
              ? date.getFullYear() - 1 + "-" + args.time + ":00"
              : date.getFullYear() + "-" + args.time + ":00";
          let end = new Date(window.$moment(nowTime).valueOf());
          end.setTime(end.getTime() + 3600 * 1000);
          let endTime = globalFormatDate(end);
          let range = nowTime + "~" + endTime;
          params["span"] = range;
          break;
        case "ascendkey":
          params["keyword"] = args.keyword;
          params["highlight"] = args.keyword;
          break;
        case "hotkey":
          params["keyword"] = args.keyword;
          params["highlight"] = args.keyword;
          break;
        case "hotarea":
          params["province"] = args.province;
          break;
      }
      if (key == "trend") {
        this.$refs.list.requestListData(
          `${window.service.api}/message/search`,
          params,
          args.phonyTotal
        ); // 传递数据总量
      } else {
        this.$refs.list.requestListData(
          `${window.service.api}/message/search`,
          params
        );
      }
    },
  },
};
</script>
<style scoped>
.overview-cont >>> .el-card__body {
  padding: 20px;
}
.overview-cont .mod-title {
  margin-left: 0;
  position: relative;
}
.zl-list >>> .el-card {
  height: 140px;
}
.zl-list >>> .el-card__body {
  padding: 0 20px;
  font-size: 12px;
}
.zl-list .image {
  margin-top: 20px;
}
.zl-list .item {
  text-align: left;
  line-height: 20px;
  width: 100%;
  margin-top: 15px;
}
.zl-list .item p:first-child {
  font-size: 14px;
}
.zl-list p.strong {
  font-size: 24px;
  font-weight: 400;
  line-height: 40px;
}
.cor-555 {
  color: #555dfe;
}
.cor-fc5 {
  color: #fc5d73;
}
.cor-8f6 {
  color: #8f65dd;
}
.cor-fd9 {
  color: #fd9107;
}
.el-icon-caret-top,
.el-icon-caret-bottom {
  margin-right: 10px;
  font-size: 16px;
}
.zl-list .bottom {
  overflow: hidden;
  border-top: 1px solid #dcdcdc;
  margin-top: 10px;
}
.zl-list dl {
  width: 150px;
  float: left;
  margin-bottom: 0;
}
.zl-list dd {
  width: 65px;
  padding-right: 10px;
  display: inline-block;
}
.zl-list dd p {
  line-height: 20px;
}
.zl-list dd p.strong {
  font-size: 14px;
  line-height: 25px;
}
.zl-list >>> .el-progress-bar__inner {
  background: #fd9107;
}
/*热门焦点信息*/
.hot_focus >>> .el-card__body {
  padding: 20px 20px 0;
}
.hot_focus >>> .el-tabs__header {
  margin: -20px 165px 0;
}
.hot_focus >>> .el-tabs__active-bar {
  display: none;
}
.hot_focus >>> .el-tabs--top .el-tabs__item {
  height: 32px;
  line-height: 32px;
  padding: 0 10px;
  text-align: center;
  margin: 0 10px;
  float: left;
}
.hot_focus >>> .el-tabs__content {
  padding: 10px 0;
  box-shadow: none;
}
.hot_focus .mod-list-title2 {
  width: 67%;
}
.hot_focus .mod-list-title2 span:first-child {
  max-width: 88%;
}
.hot_focus li {
  height: 40px;
  line-height: 40px;
}
.hot_focus .mod-echarts-box {
  height: 280px;
}
.hot_focus .mod-echarts-cont {
  height: 240px;
}
.hot_focus >>> .el-loading-mask {
  top: 10px;
  height: 270px;
}
/*地区声量分布*/
.hot-area .mod-echarts-cont {
  width: 75%;
  text-align: center;
}
.hotarea-content {
  position: absolute;
  right: 50px;
  bottom: 15%;
  z-index: 2001;
  background: rgba(198, 207, 255, 0.2);
  border-radius: 2px;
  width: 124px;
  height: 162px;
}
.hotarea-content h3 {
  width: 96px;
  height: 26px;
  line-height: 26px;
  font-weight: 600;
  text-align: center;
  overflow: hidden;
  margin: 15px auto 5px;
  background-image: url(../../assets/images/common/viocedistrict_bg.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  font-size: 14px;
}
.hotarea-content li {
  padding: 0 10px;
}
.hotarea-content .num {
  margin-right: 10px;
}
.hotarea-content li a {
  width: 78px;
  float: left;
  font-size: 12px;
}
@media screen and (max-width: 1381px) {
  .hot-area .mod-echarts-cont {
    width: 100%;
  }
  .hotarea-content {
    display: none;
  }
}
/* 热门词云 */
.hot-wordcloud .icon-cipin,
.hot-wordcloud .icon-ciyun {
  position: absolute;
  top: 5px;
}
.hot-wordcloud .icon-cipin {
  right: 30px;
}
.hot-wordcloud .icon-ciyun {
  right: 60px;
}
/* 行业重点信息 */
.industry-information {
  height: 320px;
}
.industry-information li a {
  cursor: pointer;
  width: calc(100% - 110px);
}
.industry-information .tag {
  width: 60px;
  height: 20px;
  line-height: 18px;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  float: left;
  margin: 12px 20px;
  cursor: pointer;
}
.industry-information >>> .el-carousel__container {
  height: 225px;
}
.industry-information >>> .el-carousel__indicators--outside button {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  opacity: 1;
  background: #d2d2d2;
}
</style>
